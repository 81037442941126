/**
 *  Copyright [2022] [https://www.xiaonuo.vip]
 *	Snowy采用APACHE LICENSE 2.0开源协议，您在使用过程中，需要注意以下几点：
 *	1.请不要删除和修改根目录下的LICENSE文件。
 *	2.请不要删除和修改Snowy源码头部的版权声明。
 *	3.本项目代码可免费商业使用，商业使用请保留源码和相关描述文件的项目出处，作者声明等。
 *	4.分发源码时候，请注明软件出处 https://www.xiaonuo.vip
 *	5.不可二次分发开源参与同类竞品，如有想法可联系团队xiaonuobase@qq.com商议合作。
 *	6.若您的项目无法满足以上几点，需要更多功能代码，获取Snowy商业授权许可，请在官网购买授权，地址为 https://www.xiaonuo.vip
 */
import { APP_CONFIG } from '@/config/index'
import { useGlobalStore } from '@/store/global'

// 系统路由
const routes = [
	{
		name: 'layout',
		path: '/',
		component: () => import('@/layout/index.vue'),
		redirect: () => {
			const menus = useGlobalStore().menus
			return menus?.[0] ? menus?.[0].children[0].path : APP_CONFIG.DASHBOARD_URL
		},
		children: []
	},
	{
		path: '/login',
		component: () => import('@/views/auth/login/login.vue'),
		meta: {
			title: '登录'
		}
	},
	{
		path: '/findpwd',
		component: () => import('@/views/auth/findPwd/index.vue'),
		meta: {
			title: '找回密码'
		}
	},
	{
		path: '/callback',
		component: () => import('@/views/auth/login/callback.vue'),
		meta: {
			title: '三方登录'
		}
	},
	{
		path: '/preview',
		component: () => import('@/views/biz/components/preview-file.vue'),
		meta: {
			title: '文件预览'
		}
	},
	{
		path: '/reviewPage',
		component: () => import('@/views/biz/jproject/docCategory/drawer-pageoffice-index.vue'),
		meta: {
			title: '预览审核'
		}
	},
	{
		path: '/largeScreen-dark',
		redirect: '/largeScreen-dark/overallSituation',
		component: () => import('@/views/largeScreen/dark/index.vue'),
		meta: {
			title: '大屏'
		},
		children: [
			{
				path: '/largeScreen-dark/overallSituation',
				component: () => import('@/views/largeScreen/dark/page/overallSituation.vue'),
				meta: {
					title: '综合情况'
				}
			},
			{
				path: '/largeScreen-dark/ProgressStatus',
				component: () => import('@/views/largeScreen/dark/page/ProgressStatus.vue'),
				meta: {
					title: '进度情况'
				}
			},
			{
				path: '/largeScreen-dark/qualityCondition',
				component: () => import('@/views/largeScreen/dark/page/quality-condition.vue'),
				meta: {
					title: '质量情况'
				}
			},
			{
				path: '/largeScreen-dark/securitySituation',
				component: () => import('@/views/largeScreen/dark/page/security-situation.vue'),
				meta: {
					title: '安全情况'
				}
			},
			{
				path: '/largeScreen-dark/acceptanceStatus',
				component: () => import('@/views/largeScreen/dark/page/acceptance-status.vue'),
				meta: {
					title: '验收情况'
				}
			}
		]
	},
	{
		path: '/largeScreen-dark-blue',
		redirect: '/largeScreen-dark-blue/overallSituation',
		component: () => import('@/views/largeScreen/dark-blue/index.vue'),
		meta: {
			title: '大屏'
		},
		children: [
			{
				path: '/largeScreen-dark-blue/overallSituation',
				component: () => import('@/views/largeScreen/dark-blue/page/overallSituation.vue'),
				meta: {
					title: '综合情况'
				}
			},
			{
				path: '/largeScreen-dark-blue/ProgressStatus',
				component: () => import('@/views/largeScreen/dark-blue/page/ProgressStatus.vue'),
				meta: {
					title: '进度情况'
				}
			},
			{
				path: '/largeScreen-dark-blue/qualityCondition',
				component: () => import('@/views/largeScreen/dark-blue/page/quality-condition.vue'),
				meta: {
					title: '质量情况'
				}
			},
			{
				path: '/largeScreen-dark-blue/securitySituation',
				component: () => import('@/views/largeScreen/dark-blue/page/security-situation.vue'),
				meta: {
					title: '安全情况'
				}
			},
			{
				path: '/largeScreen-dark-blue/acceptanceStatus',
				component: () => import('@/views/largeScreen/dark-blue/page/acceptance-status.vue'),
				meta: {
					title: '验收情况'
				}
			}
		]
	},
	{
		path: '/largeScreen-light-blue',
		redirect: '/largeScreen-light-blue/overallSituation',
		component: () => import('@/views/largeScreen/light-blue/index.vue'),
		meta: {
			title: '大屏'
		},
		children: [
			{
				path: '/largeScreen-light-blue/overallSituation',
				component: () => import('@/views/largeScreen/light-blue/page/overallSituation.vue'),
				meta: {
					title: '综合情况'
				}
			},
			{
				path: '/largeScreen-light-blue/ProgressStatus',
				component: () => import('@/views/largeScreen/light-blue/page/ProgressStatus.vue'),
				meta: {
					title: '进度情况'
				}
			},
			{
				path: '/largeScreen-light-blue/qualityCondition',
				component: () => import('@/views/largeScreen/light-blue/page/quality-condition.vue'),
				meta: {
					title: '质量情况'
				}
			},
			{
				path: '/largeScreen-light-blue/securitySituation',
				component: () => import('@/views/largeScreen/light-blue/page/security-situation.vue'),
				meta: {
					title: '安全情况'
				}
			},
			{
				path: '/largeScreen-light-blue/acceptanceStatus',
				component: () => import('@/views/largeScreen/light-blue/page/acceptance-status.vue'),
				meta: {
					title: '验收情况'
				}
			}
		]
	}
]

export default routes
