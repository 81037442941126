/**
 * 维护请求状态
 * 如果使用串行需要注意参数问题，参数不同，得先自动重置状态，强制执行
 * @param {Objcect} param0 request 请求， isRunNow: 立即执行，isSerial： 串行
 * @returns
 */
export default function useRequest({ request, isRunNow = true, isSerial, fallback, isCatch = true } = {}) {
	const isLoading = ref(false)
	const isInited = ref(false)
	const isError = ref(false)
	const errorMsg = ref('')
	const result = ref(fallback)
	const isSuccess = ref()
	const emptyMsg = computed(() => {
		if (isError.value) {
			return '加载异常'
		} else if (isLoading.value) {
			return '数据加载中'
		}
		return '暂无数据'
	})

	let version = 0
	let promise = null

	const shouldAbort = () => {
		if (isSerial && promise) {
			return true
		}
	}

	const doRequest = async (...args) => {
		if (shouldAbort()) {
			await promise
			return result.value
		}

		isLoading.value = true
		isError.value = false
		isSuccess.value = false
		const theVersion = ++version

		try {
			promise = request(...args)
			const res = await promise
			isInited.value = true
			if (theVersion === version) {
				result.value = res ?? fallback
				errorMsg.value = ''
				isSuccess.value = true
			}
		} catch (e) {
			if (theVersion === version) {
				isError.value = true
				result.value = fallback
				errorMsg.value = e?.msg || '服务异常'
			}
			if (isCatch) {
				console.error(e)
			} else {
				throw e
			}
		} finally {
			isLoading.value = false
			promise = null
		}

		return result.value
	}

	// 会强制执行，即使设定了串行
	const forceRequest = async (...args) => {
		isLoading.value = false
		isError.value = false
		isSuccess.value = false

		return doRequest(...args)
	}

	if (isRunNow) {
		doRequest()
	}

	return {
		isLoading,
		isError,
		errorMsg,
		emptyMsg,
		result,
		isSuccess,
		isInited,
		doRequest,
		forceRequest
	}
}
