/**
 * 一般页面不要直接使用这个文件，要使用用全局状态里的
 * 只有请求工具等初始方法，且用到的是不会改变，不需要响应的APP_CONFIG的，可以直接使用这个文件
 *
 * SYS_CONFIG 一定要使用全局状态里的 因为它需要从后端同步信息
 */
const MTKF_CONFIG = window.MTKF_CONFIG

// 前端的默认配置  public下的config.js的配置会覆盖这里  部署时可修改
const APP_CONFIG = Object.assign(
	{
		// TokenName // Authorization
		// TOKEN_NAME: 'token',
		TOKEN_NAME: 'satoken',

		// Token前缀，注意最后有个空格，如不需要需设置空字符串 // Bearer
		TOKEN_PREFIX: '',

		// 请求是否开启缓存
		REQUEST_CACHE: false,

		// 登录类型  具体页面自己赋值
		// PAGE_TYPE: 'login'

		// 追加其他头
		HEADERS: {},

		// 首页地址
		DASHBOARD_URL: '/index',

		// 布局 经典：classical，双排菜单：doublerow
		MTKF_LAYOUT: 'classical',

		// 菜单是否折叠
		MTKF_MENU_COLLAPSE: false,

		// 模块坞
		MTKF_MODULE_UNFOLD_OPEN: true,

		// 是否开启多标签
		MTKF_LAYOUT_TAGS_OPEN: true,

		// 是否开启展示面包屑
		MTKF_BREADCRUMD_OPEN: false,

		// 顶栏是否应用主题色
		MTKF_TOP_HANDER_THEME_COLOR_OPEN: false,

		// 顶栏主题色通栏
		MTKF_TOP_HANDER_THEME_COLOR_SPREAD: false,

		// 侧边菜单是否排他展开
		MTKF_SIDE_UNIQUE_OPEN: true,

		// 整体表单风格
		MTKF_FORM_STYLE: 'modal',

		// 默认整体主题
		MTKF_THEME: 'light',

		// 语言
		MTKF_LANG: 'zh-cn',

		// 主题颜色
		MTKF_THEME_COLOR: '#4b93f5',

		// 成功色
		success: '#52c41a',

		// 警告色
		warning: '#faad14',

		// 错误色
		error: '#f5222f'
	},
	MTKF_CONFIG
)

// 系统基础配置，这些是数据库中保存起来的  系统配置可修改,以数据库为准
const SYS_CONFIG = {
	// 默认logo
	MTKF_SYS_LOGO: '/img/company-logo.svg',
	// 背景图
	MTKF_SYS_BACK_IMAGE: '',
	// 系统名称
	MTKF_SYS_NAME: 'JLPT',
	// 中文系统名称
	MTKF_SYS_NAME_CHN: '工作交流平台',
	// 版本
	MTKF_SYS_VERSION: '2.0',
	// 版权
	MTKF_SYS_COPYRIGHT: 'yjs @copy ',
	// 版权跳转URL
	MTKF_SYS_COPYRIGHT_URL: '',
	// 默认文件存储
	MTKF_SYS_DEFAULT_FILE_ENGINE: 'LOCAL',
	// 是否开启验证码
	MTKF_SYS_DEFAULT_CAPTCHA_OPEN: 'false',
	// 默认重置密码
	MTKF_SYS_DEFAULT_PASSWORD: ''
}

// 从本地存储同步配置
const syncLocalConfig = (config) => {
	Object.keys(config).forEach((key) => {
		try {
			const value = JSON.parse(localStorage.getItem(key))
			if (value || value === 0) {
				config[key] = value
			}
		} catch (err) {
			// 无需处理
		}
	})
}
syncLocalConfig(APP_CONFIG)

export { APP_CONFIG, SYS_CONFIG }
