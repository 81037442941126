/**
 * 将全局配置项设置成store, 让其具有响应式。
 * 除BASE_API等初始系统就必须用到的配置可以直接用@/config/index，其他系统配置，最好都走这里
 */

import configApi from '@/api/dev/configApi'
import { message } from 'ant-design-vue'
import { APP_CONFIG, SYS_CONFIG } from '@/config/index'
import { changeColor } from '@/utils/themeUtil'
import tool from '@/utils/tool'

import { defineStore } from 'pinia'

export const useConfigStore = defineStore('config', () => {
	// 应用配置  前端配置的
	const appConfig = ref(APP_CONFIG)

	// 系统配置 后端配置的
	const sysConfig = ref(SYS_CONFIG)
	// 系统配置是否同步过了
	let isSysSynced = false

	// 执行主题配置副作用，修改页面主题
	function initTheme() {
		const closeMessage = message.loading(`加载中...`)
		changeColor(appConfig.value['MTKF_THEME_COLOR'], appConfig.value['MTKF_THEME']).finally(closeMessage)
	}

	/**
	 * 设置应用配置
	 * @param {*} key 键
	 * @param {*} value 值
	 * @param {*} isSaveLocal 是否存到本地缓存
	 */
	function setAppConfig(key, value, isSaveLocal) {
		appConfig.value[key] = value

		// 同步更改页面，执行副作用
		if (key === 'MTKF_THEME' || key === 'MTKF_THEME_COLOR') {
			initTheme()
		}

		// 同步到localstorage里
		if (isSaveLocal) {
			tool.data.set(key, value)
		}
	}

	/**
	 * 设置系统配置
	 * @param {*} key 键
	 * @param {*} value 值
	 * @param {*} isSaveLocal 是否存到本地缓存
	 */
	function setSysConfig(key, value, isSaveLocal) {
		sysConfig.value[key] = value

		// 同步到localstorage里
		if (isSaveLocal) {
			tool.data.set(key, value)
		}
	}

	// 获取系统配置
	const sysConfigFetching = async () => {
		if (isSysSynced) {
			return sysConfig
		}

		let data = null
		try {
			data = await configApi.configSysBaseList()
		} finally {
			isSysSynced = true
		}

		if (data) {
			data.forEach((item) => {
				sysConfig.value[item.configKey] = item.configValue ? item.configValue : ''
			})
		} else {
			message.warning('表单项不存在，请初始化数据库')
		}

		return sysConfig
	}

	return {
		sysConfig,
		appConfig,
		initTheme,
		setAppConfig,
		setSysConfig,
		sysConfigFetching
	}
})
