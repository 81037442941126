import { useGlobalStore } from '@/store/global'

// 对配置分组
export const groupColumns = (settings = []) => {
	const left = []
	const content = []
	const right = []

	settings.forEach((item) => {
		if (item.fixed === 'left') {
			left.push(item)
		} else if (item.fixed === 'right') {
			right.push(item)
		} else {
			content.push(item)
		}
	})

	return {
		left,
		content,
		right
	}
}

export const cloneColumns = (settings = []) => settings.map((item) => ({ ...item }))

// 初始话默认列配置
export const initDefaultColumns = (columns, isLimit) => {
	const initNum = isLimit ? 8 : Number.MAX_VALUE
	const { left, content, right } = groupColumns(columns)
	let checkedNum = 0
	const list = []

	// 固定左右的默认展示
	;[...left, ...right].forEach((item) => {
		let checked = item.checked !== false
		checkedNum += checked ? 1 : 0
		list.push({
			...item,
			checked
		})
	})

	// 统计普通列明确指定true的
	content.forEach((item) => {
		checkedNum += item.checked === true ? 1 : 0
	})

	content.forEach((item, index) => {
		let checked = item.checked

		// 没指定的，如果还有名额，默认展示
		if (!checked && checked !== false && checkedNum < initNum) {
			checked = true
			checkedNum += 1
		}
		list.splice(left.length + index, 0, {
			...item,
			checked
		})
	})

	return list
}

const fetchFilters = (userId, pageCode) => {
	return fieldFilterApi
		.getFilter({
			userId,
			pageCode
		})
		.then((res) => {
			let result = null

			if (res?.filterJson) {
				try {
					result = JSON.parse(res.filterJson)
				} catch (error) {
					console.error(error)
				}
			}

			return result
		})
}

export const getColumnKey = (item) => item.key ?? item.dataIndex ?? item.title

// 初始化用户的列
export const syncUserColumns = async (columns, code) => {
	let userColumns = columns
	const userId = useGlobalStore()?.userInfo?.id

	// 是否同步数据 接口请求异常也标识成不同步，保存配置是辅助不是关键功能
	let isSync = false //!!code && !!userId

	// 人员原始配置
	// const settings = isSync
	// 	? await fetchFilters(userId, code).catch((error) => {
	// 			// 接口请求异常也标识成不同步，保存配置是辅助不是关键功能
	// 			isSync = false
	// 			console.error(error)
	// 	  })
	// 	: null
	const settings = null

	if (!settings || Object.keys(settings).length === 0) {
		return { userColumns, isSync }
	}

	userColumns = columns
		.map((item, index) => {
			// 有配置，使用配置，无配置，使用默认
			const newItem = { ...item, _index: index }
			const key = getColumnKey(item)
			if (settings[key]) {
				newItem.checked = settings[key].checked
			}
			return newItem
		})
		.sort((a, b) => {
			const aKey = getColumnKey(a)
			const bKey = getColumnKey(b)
			if (settings[aKey] && settings[bKey]) {
				return settings[aKey].index - settings[bKey].index
			}
			return a._index - b._index
		})

	return { userColumns, isSync }
}

export const saveUserColumns = async (columns, pageCode) => {
	const userId = useGlobalStore()?.userInfo?.id

	const settings = columns.reduce((data, item, index) => {
		data[getColumnKey(item)] = {
			index,
			checked: item.checked
		}
		return data
	}, {})

	// await fieldFilterApi.updateFilter({
	// 	userId,
	// 	pageCode,
	// 	filterJson: JSON.stringify(settings)
	// })
}
