/**
 * APP全局状态
 */
import { defineStore } from 'pinia'
import tool from '@/utils/tool'
import loginApi from '@/api/auth/loginApi'
import userCenterApi from '@/api/sys/userCenterApi'
import dictApi from '@/api/dev/dictApi'

let requestMap = {} // 请求状态存储 true 为已经加载过了，promsie则是请求中
// 保证只请求一次，保证幂等性，简化操作
const fetchingWithCache = async ({ key, store, request, isHard }) => {
	if (isHard) {
		// 强制更新
		requestMap[key] = undefined
	}

	if (requestMap[key] === true) {
		return store.value
	}
	if (!requestMap[key]) {
		requestMap[key] = request()
	}

	try {
		const value = await requestMap[key]
		store.value = value
		requestMap[key] = true
	} catch (e) {
		requestMap[key] = null
		throw e
	}

	return store.value
}

// 全局状态
export const useGlobalStore = defineStore('global', () => {
	const isMobile = ref(false)
	const userInfo = ref({})
	const token = ref(tool.token.get())
	const menus = ref([])
	const dict = ref([])

	const setIsmobile = (value) => {
		isMobile.value = value
	}

	const setToken = (value) => {
		token.value = value

		if (value) {
			tool.token.set(value)
		} else {
			tool.token.remove()
		}

		clearInfo()
	}

	const setUserInfo = (value) => {
		userInfo.value = value
	}

	const userInfoFetching = async (isHard) => {
		return await fetchingWithCache({
			key: 'userInfo',
			store: userInfo,
			isHard,
			request: loginApi.getLoginUser
		})
	}

	const menusFetching = async (isHard) => {
		return await fetchingWithCache({
			key: 'menus',
			store: menus,
			isHard,
			request: userCenterApi.userLoginMenu
		})
	}

	const dictFetching = async (isHard) => {
		return await fetchingWithCache({
			key: 'dict',
			store: dict,
			isHard,
			request: dictApi.dictTree
		})
	}

	// app信息准备，没有这些信息，则系统运行没意义
	const appPreparing = async () => {
		await Promise.all([userInfoFetching(), menusFetching(), dictFetching()])
	}

	// 清除信息
	const clearInfo = () => {
		requestMap = {}
		userInfo.value = {}
		menus.value = []
		dict.value = []
	}

	// 清除所有
	const clear = () => {
		setToken(null)
		clearInfo()
	}

	return {
		isMobile,
		userInfo,
		menus,
		token,
		dict,
		setToken,
		setIsmobile,
		setUserInfo,
		userInfoFetching,
		menusFetching,
		dictFetching,
		clear,
		appPreparing
	}
})
