<template>
	<div class="column-settings">
		<div class="column-settings__header">
			<a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAll"> 列展示 </a-checkbox>
			<a @click="onReset">重置</a>
		</div>
		<a-divider />
		<div class="column-settings__body">
			<template v-for="element of columnsSettings.left" :key="element.dataIndex">
				<ColumnItem v-model:checked="element.checked" no-handle>{{ element.title }}</ColumnItem>
			</template>
			<draggable :list="columnsSettings.content" item-key="dataIndex" animation="300" @end="onDragEnd">
				<template #item="{ element }">
					<ColumnItem v-model:checked="element.checked">{{ element.title }}</ColumnItem>
				</template>
			</draggable>
			<template v-for="element of columnsSettings.right" :key="element.dataIndex">
				<ColumnItem v-model:checked="element.checked" no-handle>{{ element.title }}</ColumnItem>
			</template>
		</div>
		<template v-if="isSync">
			<a-divider />
			<div class="column-settings__footer">
				<a-button type="link" @click="onCancel">取消</a-button>
				<a-button type="primary" size="small" :loading="isLoading" @click="onSave">保存</a-button>
			</div>
		</template>
	</div>
</template>

<script setup>
	import draggable from 'vuedraggable-es'
	import ColumnItem from './ColumnItem.vue'
	import { groupColumns, saveUserColumns, cloneColumns } from '../columnsUtil'
	import { message } from 'ant-design-vue'

	const props = defineProps({
		columns: {
			type: Array,
			default: () => []
		},
		defaultColumns: {
			type: Array,
			default: () => []
		},
		userColumns: {
			type: Array,
			default: () => []
		},
		isSync: Boolean,
		pageCode: String
	})

	const emit = defineEmits('columnChange', 'userColumnChange', 'close')

	// 复制一份，避免被影响
	const defaultColomnsCopy = cloneColumns(props.defaultColumns)
	let userColomnsCopy = cloneColumns(props.userColumns)

	// 渲染列表
	const columnsSettings = computed(() => {
		return groupColumns(props.columns)
	})

	const checkAll = computed(() => {
		return props.columns.every((item) => item.checked)
	})

	const indeterminate = computed(() => {
		return !checkAll.value && props.columns.some((item) => item.checked)
	})

	const onCheckAll = () => {
		const checked = !checkAll.value

		emit(
			'columnChange',
			props.columns.map((item) => ({ ...item, checked: checked }))
		)
	}

	const onReset = () => {
		emit('columnChange', cloneColumns(defaultColomnsCopy))
	}

	const onDragEnd = ({ newIndex, oldIndex }) => {
		if (newIndex === oldIndex) {
			return
		}

		const { left, content, right } = columnsSettings.value

		emit('columnChange', [...left, ...content, ...right])
	}

	const onCancel = () => {
		emit('columnChange', cloneColumns(userColomnsCopy))
		emit('close')
	}

	const { isLoading, doRequest, isSuccess } = useRequest({
		request: () => saveUserColumns(props.columns, props.pageCode),
		isRunNow: false
	})

	const onSave = async () => {
		await doRequest()

		if (!isSuccess.value) {
			return false
		}

		const newColumns = cloneColumns(props.columns)
		message.success('保存成功')
		emit('userColumnChange', newColumns)
		emit('close')
		userColomnsCopy = newColumns
	}
</script>

<style lang="less" scoped>
	.column-settings {
		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10px 16px 10px 24px;
			min-width: 180px;
		}

		&__body {
			max-height: calc(100vh - 100px);
			overflow-y: auto;
			padding: 6px 0;
		}

		&__footer {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 4px 8px;
		}
	}
	.ant-divider {
		margin: 0;
	}
</style>
<style lang="less">
	.s-table-column-settings .ant-popover-inner-content {
		padding: 0;
	}
</style>
